import { FC, ReactElement } from 'react'
import classnames from 'classnames'

import styles from './Content.module.scss'

type ContentProps = {
  children: ReactElement
}

const Content: FC<ContentProps> = ({ children }) => (
  <section className={classnames(styles.content)}>{children}</section>
)

export default Content
