import classnames from 'classnames'
import L from 'next/link'
import { FC, useState } from 'react'

import { useQueryParams } from 'hooks'
import { HOME, VIP_TICKETS } from 'routes/paths'
import { Sidebar, UserWidget } from '~shared'
import { Mobile, Desktop, Menu, Icon, IconButton, Link, Logo } from '~ui'

import styles from './Header.module.scss'
import { useVariables } from '../../../../hooks/useVariables'

type HeaderProps = {
  id?: string
  fluid?: boolean
  grantedSecureBanner?: boolean
}

const Nav = () => {
  const { MAIN_HEADER_MENU_ITEMS } = useVariables()

  return (
    <nav className={styles.navigation}>
      <ul className={styles.links}>
        {MAIN_HEADER_MENU_ITEMS.map(({ node }) => {
          if (node.path === VIP_TICKETS) {
            // marketing team wants to use h1 for vip-tickets for seo optimization
            return (
              <li className={styles.item} key={node.name}>
                <h1 style={{ all: 'inherit' }}>
                  <Link uppercase href={node.path}>
                    <span>{node.name}</span>
                  </Link>
                </h1>
              </li>
            )
          }

          return (
            <li className={styles.item} key={node.name}>
              <Link uppercase href={node.path}>
                <span>{node.name}</span>
                {node.children && (
                  <Icon name="arrow" fill="currentColor" height="1em" width="1em" />
                )}
              </Link>

              {node.children && (
                <div className={styles['sub-links']}>
                  <Menu
                    items={node.children.map(({ name: l, path: p }) => ({
                      linkProps: { href: p },
                      label: l,
                      value: l,
                    }))}
                  />
                </div>
              )}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

const Header: FC<HeaderProps> = ({ fluid = true, id, children, grantedSecureBanner }) => {
  const { pathname, paths } = useQueryParams()

  const [isOpen, setIsOpen] = useState(false)

  const onToggle = () => setIsOpen((prev) => !prev)

  return (
    <div
      className={classnames(styles['header-wrapper'], {
        [styles['granted-secure']]: grantedSecureBanner,
      })}
    >
      <Sidebar isOpen={isOpen} onClose={onToggle} />
      <header
        className={classnames(styles.header, {
          [styles['header-event']]: pathname === paths.EVENT.pathname,
        })}
      >
        <div
          className={classnames(styles['header-inner'], {
            [styles['header-inner-with-max-width']]: fluid,
          })}
        >
          <Mobile>
            <IconButton iconName={isOpen ? 'cross' : 'menu'} onClick={onToggle} />
          </Mobile>

          {id ? (
            <Desktop>
              <L href={HOME}>
                <a style={{ display: 'flex' }}>
                  <Logo />
                </a>
              </L>
            </Desktop>
          ) : (
            <L href={HOME}>
              <a style={{ display: 'flex' }}>
                <Logo />
              </a>
            </L>
          )}

          {children}

          {!children && <Nav />}

          <UserWidget />
        </div>
      </header>
    </div>
  )
}

export default Header
