import classnames from 'classnames'
import { FC } from 'react'

import styles from './Sidebar.module.scss'

type SidebarProps = {
  expandable?: boolean // TODO: needs discussion and design
}

const Sidebar: FC<SidebarProps> = ({ expandable = false, children }) => (
  <section
    className={classnames(styles.sidebar, {
      [styles.expandable]: expandable,
    })}
  >
    {children}
  </section>
)

export default Sidebar
