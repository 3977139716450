import { FC } from 'react'
import classnames from 'classnames'

import styles from './Main.module.scss'

const Main: FC<{
  withSidebar?: boolean
  withPadding?: boolean
  backgroundColor?: GThemeColorsNew
}> = ({ backgroundColor = 'g-black', withPadding = false, withSidebar = true, children }) => (
  <main
    className={classnames(styles.main, styles[backgroundColor], {
      [styles['main-with-sidebar']]: withSidebar,
      [styles['main-with-padding']]: withPadding,
    })}
  >
    {children}
  </main>
)

export default Main
