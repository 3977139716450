import { FC, useState } from 'react'
import classnames from 'classnames'
import L from 'next/link'

import { GET_USER_ACTIVE_SHORT_CART } from 'gql/queries'
import SearchInput from 'components/search-input'
import { HOME, VIP_TICKETS } from 'routes/paths'
import { Sidebar, UserWidget } from 'components/shared'
import { Link, Button, Badge, Desktop, Mobile } from 'components/ui'
import { useQuery } from '@apollo/client'
import Logo from '../../Logo'
import styles from './Header.module.scss'
import { useVariables } from '../../../../hooks/useVariables'

type HeaderProps = {
  fluid?: boolean
  withSearch?: boolean
}

const Nav = () => {
  const { MAIN_HEADER_MENU_ITEMS } = useVariables()

  return (
    <nav>
      <ul className={styles.links}>
        {MAIN_HEADER_MENU_ITEMS.map(({ node: { name, path } }) => {
          if (path === VIP_TICKETS) {
            // marketing team wants to use h1 for vip-tickets for seo optimization
            return (
              <li className={styles.item} key={name}>
                <h1 style={{ all: 'inherit' }}>
                  <Link uppercase href={path}>
                    {name}
                  </Link>
                </h1>
              </li>
            )
          }
          return (
            <li className={styles.item} key={path}>
              <Link uppercase href={path}>
                {name}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

const MobileHeader: FC<Pick<HeaderProps, 'withSearch'>> = ({ withSearch }) => {
  const [isOpen, setOpen] = useState(false)

  const { data: cartInfo } = useQuery(GET_USER_ACTIVE_SHORT_CART)

  const onToggle = () => setOpen((prev) => !prev)

  return (
    <>
      <Sidebar isOpen={isOpen} onClose={onToggle} />

      <header className={classnames(styles.header, styles['mobile-header'])}>
        <div className={styles.top}>
          <L href={HOME}>
            <a style={{ display: 'flex' }}>
              <Logo />
            </a>
          </L>

          <Badge color="pink" dot={!!cartInfo?.findCart?.id}>
            <Button icon="menu" className={styles.humbugger} onClick={onToggle} />
          </Badge>
        </div>

        {withSearch && (
          <SearchInput
            types={['stars', 'venues', 'events', 'tours']}
            inputPlaceholder="Event, performer or venue"
            debounceInMs={500}
          />
        )}
      </header>
    </>
  )
}

const HomeHeader: FC<HeaderProps> = ({ fluid, withSearch = true }) => (
  <>
    <Desktop>
      <header className={classnames(styles.header)}>
        <div className={classnames(styles.wrapper, { [styles.fluid]: fluid })}>
          <div className={styles.top}>
            {withSearch && (
              <div style={{ position: 'absolute', left: 0 }}>
                <SearchInput
                  types={['stars', 'venues', 'events', 'tours']}
                  inputPlaceholder="Event, performer or venue"
                  debounceInMs={500}
                />
              </div>
            )}

            <L href={HOME}>
              <a style={{ display: 'flex' }}>
                <Logo />
              </a>
            </L>

            <div style={{ position: 'absolute', right: 0 }}>
              <UserWidget />
            </div>
          </div>

          <div className={styles.bottom}>
            <Nav />
          </div>
        </div>
      </header>
    </Desktop>

    <Mobile>
      <MobileHeader withSearch={withSearch} />
    </Mobile>
  </>
)

export default HomeHeader
